import React from 'react'
import Header from './components/Header'
import Navigation from './components/Navigation'
import Portfolio from './components/Portfolio'
import Stack from './components/Stack'
import Footer from './components/Footer'
// import Collabs from './components/Collabs'
function App () {
  return (
    <>
      
        <Navigation />
        <Header />
        <Stack />
        <Portfolio />
        {/* <Collabs /> */}
        <Footer />
      
    </>
  )
}

export default App
